import { Button, Css, Icon, useModal, useTestIds } from "@homebound/beam";
import { useSuspense } from "@rest-hooks/react";
import { useEffect, useState } from "react";
import { ReadyPlanOutdatedModal } from "src/components/outdatedReadyPlanModal/ReadyPlanOutdatedModal";
import { PropCoEndpoint } from "src/routes/cma/endpoints/PropCoEndpoint";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { postUwValuationStages, ValuationStage } from "src/routes/cma/endpoints/reports/ValuationStage";
import { BlueprintReadyPlanComputeDataEndpoint } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanComputeDataEndpoint";
import { BlueprintOptionGroupsEndpoint } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanOptionsEndpoint";
import {
  checkBlueprintRPTemplateVersion,
  CheckRPTemplateVersionResponse,
} from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlansEndpoint";
import { compareArrays } from "src/utils";
import { BlueprintProjectUpdatesModal } from "./outdatedReadyPlanModal/BlueprintProjectUpdatesModal";
import { getProgramDataChanges } from "./outdatedReadyPlanModal/components/PlanTemplateOutdatedContent";
import { reportIsReadOnly } from "src/utils/reports";

type ReadyPlanOutdatedBannerProps = {
  report: UnderwritingReport;
};

type UpdatedProjectInfo = {
  missingOptions: string[];
  addedOptions: string[];
  changedProgramData: { label: string; old: number; new: number }[];
  bpSelectedOptionIds: string[];
};

export function ReadyPlanOutdatedBanner(props: ReadyPlanOutdatedBannerProps) {
  const [bpVersionCheckRes, setBpVersionCheckRes] = useState<CheckRPTemplateVersionResponse | null>(null);
  const [updatedProjectInfo, setUpdatedProjectInfo] = useState<UpdatedProjectInfo | null>(null);

  const { openModal } = useModal();
  const tid = useTestIds(props, "readyPlanOutdatedBanner");
  const { report } = props;
  const reportReadyPlan = report?.ready_plans?.[0];

  // check to see if report is in a valuation stage after Underwriting and not in a read-only state
  const isPostUwAndEditable =
    report.valuation_stage && postUwValuationStages.includes(report.valuation_stage) && !reportIsReadOnly(report);

  // Query BP for project info, option groups, and cost info if we are in a post-UW valuation stage
  const { lot } = useSuspense(
    PropCoEndpoint,
    isPostUwAndEditable && report.opportunity_id ? { opportunityId: report.opportunity_id } : null,
  );

  const { optionGroups } = useSuspense(
    BlueprintOptionGroupsEndpoint,
    isPostUwAndEditable
      ? {
          devId: "", // not used in endpoint, so ok to pass in empty string
          bp_ready_plan_id: reportReadyPlan!.bp_ready_plan_id!,
        }
      : null,
  );

  const computeData = useSuspense(
    BlueprintReadyPlanComputeDataEndpoint,
    isPostUwAndEditable && lot
      ? {
          devId: "",
          bp_ready_plan_id: reportReadyPlan!.bp_ready_plan_id!,
          options: lot.partitions[0].readyPlanOptionIds?.split(",").sort() ?? [],
        }
      : null,
  );

  useEffect(() => {
    const checkBpTemplateVersion = async () => {
      // Only UW reports are dependent on being in sync with BP prior to finalization
      if (report.valuation_stage === ValuationStage.underwriting) {
        const data = await checkBlueprintRPTemplateVersion({
          rpTemplateId: report.ready_plans![0].bp_ready_plan_id!,
          rpTemplateVersion: report.ready_plans![0].bp_ready_plan_version!,
        });
        setBpVersionCheckRes(data);
      }
    };

    checkBpTemplateVersion();
    // We want one, cache-free, check on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // These will only be defined if we are in a post-UW valuation stage and an editable report
    if (lot && computeData && reportReadyPlan && optionGroups) {
      // Find the missing and added option ids
      const reportReadyPlanOptionIds = reportReadyPlan.bp_ready_plan_option_ids?.sort() ?? [];
      const bpSelectedOptionIds = lot.partitions[0]?.readyPlanOptionIds?.split(",").sort() ?? [];
      const { missing: missingOptions, added: addedOptions } = compareArrays(
        reportReadyPlanOptionIds,
        bpSelectedOptionIds,
      );

      // Check for changes
      const changedProgramData = getProgramDataChanges(computeData, reportReadyPlan);
      const optionsChanged = missingOptions.length > 0 || addedOptions.length > 0;

      const programDataChanged = changedProgramData.length > 0;

      if (!optionsChanged && !programDataChanged) {
        setUpdatedProjectInfo(null);
      } else {
        setUpdatedProjectInfo({ missingOptions, addedOptions, changedProgramData, bpSelectedOptionIds });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computeData]);

  if (!updatedProjectInfo && (!bpVersionCheckRes || bpVersionCheckRes.isLatestVersion)) {
    return null;
  }

  const bannerMessage = {
    bpProjectChanges: "Changes have been made to the project in Blueprint.",
    outOfSync: "This report's configured ready plan is out of sync with its Blueprint version.",
    missingVersion:
      "We're unable to confirm that this ready plan is in sync with Blueprint because its version is missing.",
  };

  const messageType = getMessageType(bpVersionCheckRes, updatedProjectInfo);

  return (
    <div
      {...tid}
      css={
        Css.fs0.ba.br4.bcGray300.w100.hPx(48).my2.px3.df.aic.jcsb.if(messageType === "missingVersion").bgRed200.else
          .bgYellow200.$
      }
    >
      <div css={Css.df.aic.gap1.$}>
        <Icon icon={messageType === "missingVersion" ? "error" : "outlineFlag"} />
        {bannerMessage[messageType]}
      </div>
      <div css={Css.df.gap2.$}>
        {bpVersionCheckRes ? (
          <Button
            onClick={() =>
              openModal({
                content: <ReadyPlanOutdatedModal report={report} checkResponse={bpVersionCheckRes} />,
                size: "lg",
              })
            }
            label="Update"
          />
        ) : (
          <Button
            onClick={() => {
              const { missingOptions, addedOptions, changedProgramData, bpSelectedOptionIds } = updatedProjectInfo!;
              openModal({
                content: (
                  <BlueprintProjectUpdatesModal
                    report={report}
                    optionGroups={optionGroups!}
                    missingOptions={missingOptions}
                    addedOptions={addedOptions}
                    changedProgramData={changedProgramData}
                    bpSelectedOptionIds={bpSelectedOptionIds}
                  />
                ),
              });
            }}
            label="View Changes"
          />
        )}
      </div>
    </div>
  );
}

function getMessageType(
  bpVersionCheckRes: CheckRPTemplateVersionResponse | null,
  updatedProjectInfo: UpdatedProjectInfo | null,
) {
  if (updatedProjectInfo) {
    return "bpProjectChanges";
  }
  if (bpVersionCheckRes?.currentIncrement) {
    return "outOfSync";
  }
  return "missingVersion";
}
