import { Button, Css, SelectField, useModal } from "@homebound/beam";
import { useSuspense } from "@rest-hooks/react";
import { useEffect, useMemo, useState } from "react";
import { ConfirmationModal } from "src/components/ConfirmationModal";
import { useController } from "src/hooks";
import {
  SaveUnderwritingReportEndpoint,
  SaveUnderwritingReportInput,
  UnderwritingReport,
} from "src/routes/cma/endpoints/reports";
import {
  BlueprintReadyPlan,
  BlueprintReadyPlansEndpoint,
  ProductOfferingConfig,
} from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlansEndpoint";
import { getPropertyTypeFromBpRp, getReadyPlanType, getReadyPlanVersion } from "src/routes/cma/steps/readyPlanUtils";
import { readyPlanStepIsReadOnly } from "src/utils/reports";
import { ReadyPlanNameLabel } from "./components/ReadyPlanNameLabel";
import { BlueprintOptionGroupsEndpoint } from "./endpoints/BlueprintReadyPlanOptionsEndpoint";

type LoadBpReadyPlanSelectFieldsProps = {
  devId: string;
  report: UnderwritingReport;
};

export function LoadBpReadyPlanSelectFields(props: LoadBpReadyPlanSelectFieldsProps) {
  const { devId, report } = props;
  const readOnly = readyPlanStepIsReadOnly(report);
  const bpReadyPlans = useSuspense(BlueprintReadyPlansEndpoint, readOnly ? null : { devId });

  return <BpReadyPlanSelectFields bpReadyPlans={bpReadyPlans} report={report} readOnly={readOnly} devId={devId} />;
}

type BpReadyPlanSelectFieldsProps = {
  bpReadyPlans: BlueprintReadyPlan[] | undefined;
  devId: string;
  readOnly: boolean;
  report: UnderwritingReport;
};

export function BpReadyPlanSelectFields(props: BpReadyPlanSelectFieldsProps) {
  const { openModal, closeModal } = useModal();
  const { fetch, invalidate } = useController();
  const { bpReadyPlans: plans = [], report, readOnly, devId } = props;
  const reportReadyPlan = report?.ready_plans?.[0];
  const [bpReadyPlanId, setBpReadyPlanId] = useState(reportReadyPlan?.bp_ready_plan_id);

  const configs = useMemo(
    () => plans.find((rp) => rp.id === bpReadyPlanId)?.productOfferingConfigs ?? [],
    [plans, bpReadyPlanId],
  );
  const [bpConfig, setBpConfig] = useState(configs.find((c) => c.id === reportReadyPlan?.bp_poc_id));
  const bpProductOfferingUrl = useMemo(
    () => plans.find((p) => p.id === bpReadyPlanId)?.bpProductOfferingUrl,
    [bpReadyPlanId, plans],
  );

  useEffect(() => {
    const newConfig = configs.find((c) => c.id === reportReadyPlan?.bp_poc_id);
    setBpConfig(newConfig);
  }, [reportReadyPlan?.bp_poc_id, configs]);

  if (readOnly) {
    return (
      <div data-testid="readonlyReadyPlan">
        <div css={Css.gray700.mbPx(4).$}>Ready Plan</div>
        <div css={Css.smMd.$}>{reportReadyPlan?.bp_ready_plan_name}</div>
      </div>
    );
  }

  // Changing a readyPlan must reset all other fields beside Development
  async function selectReadyPlan(rp: BlueprintReadyPlan) {
    const input = {
      report: {
        dpid: report.dpid,
        ready_plans: [
          {
            property_type: getPropertyTypeFromBpRp(rp.type),
            bp_ready_plan_id: rp.id,
            bp_ready_plan_name: rp.name,
            bp_ready_plan_version: getReadyPlanVersion(rp),
            bp_ready_plan_option_ids: [],
            bp_ready_plan_options_json: {},
            lot_size: report?.reference_property?.lot_size,
            rp_type: getReadyPlanType(rp),
          },
        ],
      } as SaveUnderwritingReportInput,
      versionId: report.id,
    };

    if (bpReadyPlanId && bpReadyPlanId !== rp.id) {
      openModal({
        content: (
          <ConfirmationModal
            confirmationMessage="Changing ready plan will reset selections. Are you sure?"
            onConfirmAction={async () => {
              setBpReadyPlanId(rp.id);
              setBpConfig(undefined);
              fetch(SaveUnderwritingReportEndpoint, input);
              invalidate(BlueprintOptionGroupsEndpoint, { devId, bp_ready_plan_id: rp.id });
              closeModal();
            }}
            title="Change Ready Plan"
            label="Confirm"
          />
        ),
      });
    } else {
      setBpReadyPlanId(rp.id);
      await fetch(SaveUnderwritingReportEndpoint, input);
    }
  }

  async function applyPOConfig(poc: ProductOfferingConfig) {
    report.ready_plans![0].bp_poc_id = poc.id;
    report.ready_plans![0].bp_ready_plan_option_ids = poc.readyPlanOptionIds;

    const input = {
      report: {
        dpid: report.dpid,
        ready_plans: report.ready_plans,
      } as SaveUnderwritingReportInput,
      versionId: report.id,
    };

    await fetch(SaveUnderwritingReportEndpoint, input);
  }

  return (
    <div data-testid="bpReadyPlanSelectFields">
      <SelectField
        options={plans ?? []}
        errorMsg={plans.length < 1 ? `No Ready Plans Configured for development:${devId}` : ""}
        label="Ready Plan"
        disabledOptions={plans!
          .filter((rp) => rp.type?.code === "MFU")
          .map((rp) => ({ value: rp.id, reason: "Multi Family RPs are not supported at this time." }))}
        placeholder="Select a ready plan"
        value={bpReadyPlanId}
        getOptionMenuLabel={(rp: BlueprintReadyPlan) => <ReadyPlanNameLabel readyPlan={rp} />}
        onSelect={async (v, rp) => selectReadyPlan(rp!)}
      />
      {configs.length > 0 && (
        <div css={Css.dg.gap1.mt2.$}>
          <SelectField
            options={configs ?? []}
            label="Configuration"
            placeholder="Select a configuration to auto-select options"
            value={bpConfig?.id}
            onSelect={async (v, poc) => setBpConfig(poc)}
          />
          <div css={Css.df.aie.gap1.jcfe.$}>
            {bpProductOfferingUrl && (
              <Button
                icon="linkExternal"
                label="BP Product Offering Page"
                openInNew
                onClick={bpProductOfferingUrl}
                variant="tertiary"
              />
            )}
            <Button
              label="Apply Config"
              onClick={async () => applyPOConfig(bpConfig!)}
              disabled={!bpConfig}
              tooltip="This will set option selections to match the selected configuration"
            />
          </div>
        </div>
      )}
    </div>
  );
}
