import { PropertyType } from "src/routes/cma/endpoints";
import { RpType, SaveReadyPlanInput } from "src/routes/cma/endpoints/reports";
import { CostsAndMetricsResult } from "src/routes/cma/endpoints/reports/CostsAndMetrics";
import { ReadyPlan, UnderwritingReport } from "src/routes/cma/endpoints/reports/UnderwritingReportEndpoint";
import {
  BlueprintReadyPlan,
  BlueprintReadyPlanTypeDetail,
} from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlansEndpoint";
import { Maybe } from "src/utils";

// return ready plan info from the report that isn't this readyPlan
export function otherPlanIds(report: UnderwritingReport, readyPlan: ReadyPlan): SaveReadyPlanInput[] {
  const plans = report.ready_plans ?? [];

  if (readyPlan.bp_ready_plan_id) {
    return plans
      .filter((rp) => rp.bp_ready_plan_id !== readyPlan.bp_ready_plan_id)
      .map((rp) => ({ id: rp.id, bp_ready_plan_id: rp.bp_ready_plan_id }));
  }

  return plans
    .filter((rp) => rp.ready_plan_sub_id !== readyPlan.ready_plan_sub_id)
    .map((rp) => ({
      id: rp.id,
      ready_plan_id: rp.ready_plan_id,
      ready_plan_sub_id: rp.ready_plan_sub_id,
    }));
}

export interface ReadyPlanIds {
  readyPlanId: string;
  readyPlanSubId: string;
}

/**
 * Find a ready plan by its id
 *
 * @param report underwriting report
 * @param readyPlanId underwriting report ready plan id
 * @returns ready plan or undefined
 */
export function findPlan(report: UnderwritingReport, readyPlanId: string): ReadyPlan | undefined {
  const plan = (report.ready_plans ?? []).find((r) => {
    return readyPlanId === r.id.toString();
  });

  return plan;
}

export function parseCompBuildDate(year_built_or_renovated: string | number | null | undefined): number | undefined {
  if (typeof year_built_or_renovated === "number") {
    return year_built_or_renovated;
  }

  if (year_built_or_renovated) {
    const parts = year_built_or_renovated.split("-");
    return parseInt(parts[0]);
  }
}

// Returns appreciated cumulative high/lows for ready plan sale price and investor margins
export function getEstRanges(costs?: CostsAndMetricsResult, readyPlans?: ReadyPlan[]) {
  if (!costs || !readyPlans || readyPlans.some((rp) => !rp.final_weighted_price_high || !rp.final_weighted_price_low))
    return null;

  const getNetAfterRealtor = (price: number, realtorFee: number) => price * (1 - realtorFee / 100);
  const getMargin = (price: number, cost: number) => (price - cost) / price;

  const {
    total_costs,
    appreciation_time_of_delivery_appreciation: appreciation = 0,
    net_sale_price_realtor_fee,
  } = costs;

  const weightedPrices = {
    high: readyPlans
      .map(({ final_weighted_price_high, copies }) => final_weighted_price_high! * copies)
      .reduce((acc, p) => acc + p, 0),
    low: readyPlans
      .map(({ final_weighted_price_low, copies }) => final_weighted_price_low! * copies)
      .reduce((acc, p) => acc + p, 0),
  };

  const weightedPricesWithAppreciation = {
    high: weightedPrices.high * (appreciation + 1),
    low: weightedPrices.low * (appreciation + 1),
  };

  const investorMargins = {
    highWithAppreciation: getMargin(
      getNetAfterRealtor(weightedPricesWithAppreciation.high, net_sale_price_realtor_fee!),
      total_costs,
    ),
    lowWithAppreciation: getMargin(
      getNetAfterRealtor(weightedPricesWithAppreciation.low, net_sale_price_realtor_fee!),
      total_costs,
    ),
    high: getMargin(getNetAfterRealtor(weightedPrices.high, net_sale_price_realtor_fee!), total_costs),
    low: getMargin(getNetAfterRealtor(weightedPrices.low, net_sale_price_realtor_fee!), total_costs),
  };

  return { weightedPrices, weightedPricesWithAppreciation, investorMargins };
}

/**
 * Gets a property-api specific string for ReadyPlan.property_type based on BP code.
 * BP uses an enum for code
 * @return {string} "adu" | "single_family"
 */
export function getPropertyTypeFromBpRp(type: BlueprintReadyPlanTypeDetail | undefined): PropertyType {
  switch (type?.code) {
    case "ADU":
      return "adu";
    case "MFU":
      throw new Error("Multi family unit ready plans are not supported");
    case "SFH":
    default:
      return "single_family";
  }
}

export function hasV2ReadyPlans(report: UnderwritingReport): boolean {
  return report.ready_plans?.some((rp) => rp.bp_ready_plan_id) ?? false;
}

export const READY_PLAN_SPEC_LEVELS = ["base", "essential", "deluxe", "premium", "custom"];

// Only a property/property comp can have a between (+) spec level for more accurate adjustments
export const PROPERTY_SPEC_LEVELS = [
  "base",
  "base+",
  "essential",
  "essential+",
  "deluxe",
  "deluxe+",
  "premium",
  "premium+",
  "custom",
];

export function getReadyPlanVersion(rp: BlueprintReadyPlan): Maybe<string> {
  return rp.aggregateActive?.version?.toString() ?? rp.underwritingTemplate?.version;
}

export function getReadyPlanType(rp: BlueprintReadyPlan): Maybe<RpType> {
  if (rp.aggregateActive) {
    return RpType.BpProductOffering;
  } else if (rp.underwritingTemplate) {
    return RpType.BpReadyPlan;
  }
}
