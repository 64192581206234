import { Endpoint, Entity } from "@rest-hooks/rest";
import FileSaver from "file-saver";
import { MultiPolygon } from "geojson";
import { api_url, auth_headers } from "src/context";
import { ActivityState } from "src/routes/cma/endpoints/reports/ActivityState";
import { ReportCta } from "src/routes/cma/endpoints/reports/ctas";
import { ValuationStage } from "src/routes/cma/endpoints/reports/ValuationStage";
import { JSONObject } from "src/routes/cma/steps/estimate/components/AppreciationPrediction";
import { BlueprintReadyPlanOptionGroup } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanOptionsEndpoint";
import { UnderwritingOpportunity } from "src/routes/opportunities/endpoints/OpportunityQueueEndpoint";
import { Maybe } from "src/utils/types";
import { UnderwritingReportStatus } from "../../UnderwritingReportForm";
import { PropertyComp } from "../PropertyCompEndpoint";
import { CostsAndMetricsResult } from "./CostsAndMetrics";
import { RpType } from "./SaveUnderwritingReportEndpoint";

export interface Collaborator {
  id: number;
  email: string;
}

export interface ManualPropertyAdjustment {
  id: number;
  description: string;
  value_type: string;
  value: number;
}

export interface ReferenceProperty {
  id: number;
  dpid: string;
  metro: string;
  lot_size: number;
  buildable_sqft: number;
  adu_elig: boolean;
  zoning: string;
  lot_frontage_feet?: Maybe<number>;
  lot_depth_feet?: Maybe<number>;
  subject_property_market_value?: Maybe<number>;
  site_acquisition_bid_recommendation?: Maybe<number>;
  property_type_simple?: Maybe<string>;
  mls_url?: Maybe<string>;
  mls_date_listed?: Maybe<string>;
  is_in_floodplain?: Maybe<boolean>;
}

/**
 * What ID means what:
 *
 * `id` unique primary key of the ready plan. This is the ID that should be used within our system.
 *
 * V1 rp generated from hardcoded BE ready_plan.json file:
 * - `ready_plan_id` & `ready_plan_sub_id`
 * - EX: "large_main_and_small_adu" & "main_adu"
 *
 * V2 rp generated using blueprint API. Anything "`{string}:{number}`" format is a graphql-service originated id:
 * - `bp_ready_plan_id` & `bp_ready_plan_name`
 * - EX: "rp:1" & "The Daniel"
 * - `bp_ready_plan_option_ids` contain option ids of ALL configured options
 * - EX: ["rpo:5", "rpo:344", "rpo:112"]
 * - `bp_ready_plan_options_json` is a json object that contains all configured optionGroups used in order to rebuild the
 * ready plan form when after the report is finalized
 */
export interface ReadyPlan {
  id: number;
  underwriting_report_id: number;
  ready_plan_id?: Maybe<string>;
  ready_plan_sub_id?: Maybe<string>;

  bp_ready_plan_id?: Maybe<string>;
  bp_ready_plan_name?: Maybe<string>;
  // BP Product Offering Config Id
  bp_poc_id?: Maybe<string>;
  bp_ready_plan_option_ids?: Maybe<string[]>;
  bp_ready_plan_options_json?: Maybe<{ optionGroups: BlueprintReadyPlanOptionGroup[] }>;
  // the template or product offering version our created rp is based on. Must be aligned prior to finalizing report to ensure accurate cost
  // and proper project creation in BP
  bp_ready_plan_version?: Maybe<string>;
  rp_type?: Maybe<RpType>;

  num_bedrooms: number;
  num_baths: number;
  num_half_baths?: number;
  num_stories: number;
  num_garage_attached: number;
  num_garage_port: number;
  num_closets_in_primary_suite: number;
  lot_size: number;
  sellable_sqft: number;
  sellable_basement_sqft: number;
  above_ground_sqft: number;
  below_ground_sqft: number;
  width_in_inches: number;
  depth_in_inches: number;
  hard_costs_per_sqft: number;
  direct_hard_cost_in_cents?: number;
  indirect_hard_cost_in_cents?: number;
  soft_cost_in_cents?: number;
  copies: number;
  property_type: string;
  spec_level?: Maybe<string>;

  sale_price_tier: Maybe<"Green" | "Yellow" | "Red">;
  final_weighted_price: number;
  // comp amenity adj
  final_weighted_price_low: Maybe<number>;
  final_weighted_price_high: Maybe<number>;
  // ml CI based esp
  expected_sale_price_low: Maybe<number>;
  expected_sale_price_high: Maybe<number>;
  ml_expected_sale_price: Maybe<number>;
  ml_expected_sale_price_low: Maybe<number>;
  ml_expected_sale_price_high: Maybe<number>;
  final_weighted_price_with_appreciation: Maybe<number>;

  added_standard_adjustment_rows: Maybe<string[]>;

  comps: Maybe<PropertyComp[]>;
  property_adjustments: Maybe<ManualPropertyAdjustment[]>;
}

export interface SiteSoftCosts {
  cost_code: string;
  cost_in_cents: Maybe<number>;
}

export interface SFDCOpportunity {
  dpid: string;
  opportunity_id: Maybe<string>;
  stage_name: Maybe<string>;
}

export interface ReportMetricsChanged {
  investor_margin_diff: number;
  final_weighted_price_diff: number;
  final_weighted_price_with_appreciation_diff: number;
}

export interface UnderwritingReportFlags {
  metrics_changes?: ReportMetricsChanged;
}

export class UnderwritingReport extends Entity {
  static automaticValidation = "silent" as any;

  readonly id: Maybe<number> = undefined;
  readonly dpid: string = "";
  readonly status: UnderwritingReportStatus = "Unsaved";
  readonly collaborators: Collaborator[] = [];

  readonly final_weighted_price?: Maybe<number> = undefined;
  readonly final_weighted_price_with_appreciation?: Maybe<number> = undefined;

  readonly reference_property?: ReferenceProperty = undefined;
  readonly ready_plans?: ReadyPlan[] = undefined;
  readonly costs_and_metrics?: CostsAndMetricsResult = undefined;

  readonly offer_outcome?: Maybe<string> = undefined;
  readonly offer_outcome_notes?: Maybe<string> = undefined;

  // Note: We had a bug where some reports were finalized without this date being saved that was fixed 06/16/2023.
  readonly underwritten_at?: Maybe<string> = undefined;

  readonly activity_state: ActivityState = ActivityState.active;
  readonly updated_at: string = "";
  readonly created_at: string = "";

  readonly valuation_stage?: ValuationStage = undefined;
  readonly version_name?: Maybe<string> = undefined;

  readonly opportunity_id?: string = undefined;
  readonly opportunity?: UnderwritingOpportunity = undefined;
  readonly sfdc_opportunities?: SFDCOpportunity[] = undefined;

  readonly site_costs?: SiteSoftCosts[] = undefined;

  readonly parent_id?: Maybe<number> = undefined;

  readonly comp_search_polygon?: Maybe<MultiPolygon> = undefined;

  readonly appreciations?: Maybe<JSONObject> = undefined;

  readonly report_ctas?: ReportCta[] = [];

  readonly finalize_blockers?: string[] = [];

  pk() {
    return `${this.id}-${this.dpid}`;
  }

  static schema = {
    report_ctas: [ReportCta],
  };

  static key = "UnderwritingReport";
}

export function maybePatchComps(json: any) {
  if (json.hasOwnProperty("report")) {
    if (json["report"]["comps"] === null) {
      json["report"]["comps"] = [];
    }
  }
  return json;
}

/**
 * Latest report based on DPID. BE prioritizes:
 * 1. latest finalized and active report
 * 2. latest active report
 * 3. latest finalized report
 * 4. most recent underwritten date
 */
export const LatestDpidReportEndpoint = new Endpoint(
  async ({ dpid }: { dpid: string }) => {
    const path = `/v1/reports/${dpid}`;
    const headers = await auth_headers();
    const res = await fetch(api_url(path), { headers });

    return maybePatchComps(await res.json());
  },
  {
    schema: { report: UnderwritingReport },
    key: ({ dpid }) => {
      return `/v1/reports/${dpid}`;
    },
    name: "LatestReportEndpoint",
  },
);

export const UnderwritingReportEndpoint = new Endpoint(
  async ({
    dpid,
    versionId,
    shouldGenerateMlSaleConfidence = false,
  }: {
    dpid: string;
    versionId: string | undefined;
    shouldGenerateMlSaleConfidence?: boolean;
  }) => {
    // Ensures that we always update a reports sale price confidence with the latest metadata prior to finalizing on Est step
    const qp = shouldGenerateMlSaleConfidence ? "?ml_generate_sale_price_confidence=True" : "";

    const path = `/v1/reports/${dpid}/versions/${versionId}${qp}`;
    const headers = await auth_headers();
    const res = await fetch(api_url(path), { headers });

    return maybePatchComps(await res.json());
  },
  {
    schema: { report: UnderwritingReport },
    key: ({ dpid, versionId, shouldGenerateMlSaleConfidence = false }) => {
      const qp = shouldGenerateMlSaleConfidence ? "?ml_generate_sale_price_confidence=True" : "";
      return `/v1/reports/${dpid}/versions/${versionId}${qp}`;
    },
    name: "UnderwritingReportEndpoint",
  },
);

export const UnderwritingReportVersionsEndpoint = new Endpoint(
  async ({ dpid }: { dpid: string }) => {
    const headers = await auth_headers();
    const res = await fetch(api_url(`/v1/reports/${dpid}/versions`), {
      headers,
    });

    return await res.json();
  },
  {
    schema: { versions: [UnderwritingReport] },
    key: ({ dpid }) => {
      return `/v1/reports/${dpid}/versions`;
    },
    name: "UnderwritingReportVersionsEndpoint",
  },
);

interface downloadReportExportCsvProps {
  dpid: string;
  name: string;
}

export async function downloadReportExportCsv({ dpid, name }: downloadReportExportCsvProps) {
  const headers = await auth_headers();
  const res = await fetch(api_url(`/v1/reports/${dpid}/export`), {
    headers,
  });

  const blob = await res.blob();
  const filename = `${dpid}-${name.replaceAll(" ", "_")}.csv`;

  FileSaver.saveAs(blob, filename);
}

export class ReportCollaborator extends Entity implements Collaborator {
  readonly id: number = 0;
  readonly email: string = "";

  pk() {
    return `${this.id}`;
  }

  static key = "ReportCollaborator";
}

export const UnderwritingReportCollaboratorsEndpoint = new Endpoint(
  async () => {
    const headers = await auth_headers();
    const res = await fetch(api_url(`/v1/reports/collaborators`), {
      headers,
    });
    return await res.json();
  },
  {
    key: () => "/v1/reports/collaborators",
    schema: { collaborators: [ReportCollaborator] },
  },
);

export class ReportMetro extends Entity {
  readonly metro: string = "";

  pk() {
    return this.metro;
  }

  static key = "ReportMetro";
}

export const UnderwritingReportMetrosEndpoint = new Endpoint(
  async () => {
    const headers = await auth_headers();
    const res = await fetch(api_url(`/v1/reports/metros`), {
      headers,
    });
    return await res.json();
  },
  {
    key: () => "/v1/reports/metros",
    schema: { metros: [ReportMetro] },
    name: "UnderwritingReportMetrosEndpoint",
  },
);
