import { Css, Icon } from "@homebound/beam";
import { capitalCase } from "change-case";
import { formatNumberToString, Maybe } from "src/utils";
import { SectionTitle } from "./atoms";

type RenderProgramChangesProps = {
  programDataChanges: { label: string; old: Maybe<number>; new: Maybe<number> }[];
};

export function RenderProgramChanges({ programDataChanges }: RenderProgramChangesProps) {
  return (
    <>
      {programDataChanges.length === 0 ? (
        <SectionTitle label="Program Data has not changed" />
      ) : (
        <div>
          <SectionTitle label="Program Data Changes" success={false} />
          <div css={Css.df.fdc.gap1.mx5.$}>
            {programDataChanges.map(({ label, old, new: newComputedValue }) => {
              return (
                <ProgramDataLine
                  key={`pd-line-${label}`}
                  old={old}
                  newV={newComputedValue}
                  label={capitalCase(label)}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

type LineItemProps = {
  label: string;
  old: Maybe<number>;
  newV: Maybe<number>;
};

// Bolded by default
export const ProgramDataLine = ({ label, old, newV }: LineItemProps) => (
  <div key={label} css={Css.df.aic.jcsb.bb.bsDotted.bcGray700.$}>
    <div css={Css.smBd.$}>{label}</div>
    <div css={Css.df.aic.gap(1.5).$}>
      <div>{formatNumberToString(old, true)}</div>
      <Icon inc={2} icon="arrowRight" />
      <div>{formatNumberToString(newV, true)}</div>
    </div>
  </div>
);
