import { BoundSelectField, Css, FormLines, HasIdAndName } from "@homebound/beam";
import { required, useFormState } from "@homebound/form-state";
import { useSuspense } from "@rest-hooks/react";
import { Observer } from "mobx-react";
import { useLayoutEffect, useMemo, useRef } from "react";
import { LoadingBoundary } from "src/components/LoadingBoundary";
import { useController } from "src/hooks";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import {
  SaveUnderwritingSubjectPropertyEndpoint,
  UnderwritingSubjectPropertyEndpoint,
  UnderwritingSubjectPropertyInput,
} from "src/routes/cma/endpoints/UnderwritingSubjectPropertyEndpoint";
import { BlueprintDevelopmentsEndpoint } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintDevelopmentsEndpoint";
import {
  LoadReadyPlanOptionsForm,
  ReadyPlanOptionsForm,
} from "src/routes/cma/steps/ready-plan/v2/ReadyPlanOptionsForm";
import { Maybe } from "src/utils";
import { readyPlanStepIsReadOnly } from "src/utils/reports";
import { LoadBpReadyPlanSelectFields } from "./BpReadyPlanSelectFields";
import { ConfigSelectionContext } from "./useConfigPreviewContext";

interface ReadyPlanConfigSelectionV2Props {
  report: UnderwritingReport;
}

export function ReadyPlanConfigSelectionV2({ report }: ReadyPlanConfigSelectionV2Props) {
  const { fetch } = useController();
  const reportReadyPlan = report?.ready_plans?.[0];
  const isReadOnly = readyPlanStepIsReadOnly(report);

  const { uw_subj_property } = useSuspense(UnderwritingSubjectPropertyEndpoint, { dpid: report.dpid });
  const developmentsResult = useSuspense(BlueprintDevelopmentsEndpoint, { metro: report.reference_property!.metro });

  const developments: HasIdAndName[] = useMemo(
    () => developmentsResult?.map((d) => ({ id: d.id, name: d.name })) ?? [],
    [developmentsResult],
  );

  const configPreviewRef = useRef<HTMLDivElement | null>(null);
  const configPreviewEl = useMemo(() => document.createElement("div"), []);

  useLayoutEffect(() => {
    configPreviewRef.current!.appendChild(configPreviewEl);
  }, [configPreviewEl]);

  const subjectPropertyForm = useFormState<
    { devId: Maybe<string> },
    { uw_subj_property?: UnderwritingSubjectPropertyInput }
  >({
    config: { devId: { type: "value", rules: [required] } },
    init: {
      input: { uw_subj_property },
      map: ({ uw_subj_property }) => ({ devId: uw_subj_property?.development_id }),
    },
    readOnly: isReadOnly,
    autoSave: saveSubjectProperty,
  });

  // TODO: we either need to further split up the components or update the BE to delete RPs when this changes
  async function saveSubjectProperty() {
    await fetch(SaveUnderwritingSubjectPropertyEndpoint, {
      dpid: report.dpid,
      uw_subj_property: {
        development_id: subjectPropertyForm.devId.value!,
        development_name: developments.find((d) => d.id === subjectPropertyForm.devId.value)?.name!,
      },
    });
  }

  function renderForm() {
    return (
      <ConfigSelectionContext.Provider value={{ configPreviewEl }}>
        <div css={Css.df.$}>
          <div css={Css.mw75.$}>
            <FormLines compact>
              {/* TODO: we should be able to get the development name from property.polygon_nearest_in_rec_geo_name_active */}
              <BoundSelectField
                options={developments}
                label={"Development"}
                field={subjectPropertyForm.devId}
                placeholder="Select a development"
              />
              {subjectPropertyForm.devId.value && (
                <>
                  <LoadingBoundary>
                    <LoadBpReadyPlanSelectFields devId={subjectPropertyForm.devId.value} report={report} />
                  </LoadingBoundary>
                  {!isReadOnly && !!reportReadyPlan && reportReadyPlan.bp_ready_plan_id && (
                    <LoadReadyPlanOptionsForm
                      // reset form when the ready plan changes or the product offering configuration changes
                      key={`${reportReadyPlan.bp_ready_plan_id}-${reportReadyPlan.bp_poc_id}`}
                      report={report}
                      devId={subjectPropertyForm.devId.value}
                    />
                  )}
                  {/* bp_ready_plan_options_json only undefined on an inactive report that never had a rp configured */}
                  {isReadOnly && reportReadyPlan?.bp_ready_plan_options_json?.["optionGroups"] && (
                    <ReadyPlanOptionsForm
                      optionGroups={reportReadyPlan.bp_ready_plan_options_json["optionGroups"]}
                      devId={subjectPropertyForm.devId.value}
                      report={report}
                    />
                  )}
                </>
              )}
            </FormLines>
          </div>
          <div css={Css.mw25.$}>
            <div ref={configPreviewRef} />
          </div>
        </div>
      </ConfigSelectionContext.Provider>
    );
  }

  return <Observer>{() => renderForm()}</Observer>;
}
