import { ObjectConfig, ObjectState } from "@homebound/form-state";
import { ReadyPlan, SaveReadyPlanInput } from "src/routes/cma/endpoints/reports";
import {
  BlueprintReadyPlanComputeData,
  BlueprintReadyPlanOptionDelta,
} from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanComputeDataEndpoint";
import { BlueprintReadyPlanOptionGroup } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanOptionsEndpoint";
import { isDefined } from "src/utils";
import { Maybe } from "src/utils/types";

interface ReadyPlanRequiredOptionGroupInput {
  order: number;
  name: string;
  selectedOptionId: Maybe<string>;
}

export interface V2ReadyPlanSelectionInput {
  id: Maybe<number>; // underwriting report ready plan id
  devId: Maybe<string>;
  readyPlanName: Maybe<string>;
  // bp_ready_plan_id is the id of the ReadyPlan entity we receive from the BP API
  bp_ready_plan_id?: Maybe<string>;
  bp_ready_plan_version?: Maybe<string>;
  requiredOptionGroups: Maybe<ReadyPlanRequiredOptionGroupInput[]>;
  optionalOptionGroups: Maybe<string[]>;
  // maps back to SaveReadyPlanInput ReadyPlan metadata after all requiredOptionGroups have been selected
  // contains `deltas` for expected plan price change
  computeResults?: Maybe<BlueprintReadyPlanComputeData>;
}

export type V2ReadyPlanFormInput = V2ReadyPlanSelectionInput;
export type V2ReadyPlanFormState = ObjectState<V2ReadyPlanFormInput>;

// Our form uses 2 resources
//   1. Property API standard save report
//   2. Property API compute ready plan cost (for pricing). This depends on all `requiredOptionGroups` be filled at a minimum
export const v2ReadyPlanFormConfig: ObjectConfig<V2ReadyPlanFormInput> = {
  id: { type: "value" }, // report ready plan id
  devId: { type: "value" },
  readyPlanName: { type: "value" },
  bp_ready_plan_id: { type: "value" },
  bp_ready_plan_version: { type: "value" },
  // Rendered as select fields
  requiredOptionGroups: {
    type: "list",
    config: {
      order: { type: "value", readOnly: true },
      name: { type: "value", readOnly: true },
      selectedOptionId: { type: "value" },
    },
  },
  // CheckboxGroup field, rendered in type groups
  optionalOptionGroups: {
    type: "value",
  },
  computeResults: {
    type: "value",
    rules: [
      ({ value }) => {
        // If configuration errors
        if (
          value?.computeReadyPlanCost?.configurationErrors &&
          value?.computeReadyPlanCost?.configurationErrors?.length > 0
        ) {
          return value?.computeReadyPlanCost?.configurationErrors?.join(", ");
          // If compute still in flight block submission. Our form relies on 2 resources
        } else if (!isDefined(value)) {
          return "Computing cost";
        } else {
          return undefined;
        }
      },
    ],
  },
};

interface MapToFormInput {
  devId: string;
  optionGroups: BlueprintReadyPlanOptionGroup[];
  reportReadyPlan?: ReadyPlan;
  deltas?: BlueprintReadyPlanOptionDelta[];
}

export function mapToForm({ devId, optionGroups, reportReadyPlan }: MapToFormInput): V2ReadyPlanFormInput {
  function mapCurrentSelections() {
    const formattedRequiredOptionGroups = optionGroups
      .filter((o) => o.required)
      ?.map((mog) => {
        const selectedOptionId = mog.options.find((o) => reportReadyPlan?.bp_ready_plan_option_ids?.includes(o.id));
        return { order: mog.order, name: mog.name, selectedOptionId: selectedOptionId?.id };
      })
      .sort((a, b) => a.order - b.order);

    const requiredOptionGroupSelections = formattedRequiredOptionGroups
      ?.filter((x) => !!x.selectedOptionId)
      .map((d) => d.selectedOptionId);

    return {
      requiredOptionGroups: formattedRequiredOptionGroups,
      // Filter out option ids that have already been mapped to requiredOptionGroups
      optionalOptionGroups:
        reportReadyPlan?.bp_ready_plan_option_ids?.filter((oid) => !requiredOptionGroupSelections.includes(oid)) ?? [],
    };
  }

  return {
    id: reportReadyPlan?.id,
    devId,
    bp_ready_plan_id: reportReadyPlan?.bp_ready_plan_id,
    readyPlanName: reportReadyPlan?.bp_ready_plan_name,
    bp_ready_plan_version: reportReadyPlan?.bp_ready_plan_version,
    ...mapCurrentSelections(),
  };
}

export function buildReadyPlanInputFromForm(
  formState: V2ReadyPlanSelectionInput,
  optionGroups: BlueprintReadyPlanOptionGroup[],
): SaveReadyPlanInput {
  const computeProgramData = formState.computeResults?.computeProgramData;
  const computeReadyPlanCost = formState.computeResults?.computeReadyPlanCost;

  // Get all selected IDs (rpo:<#>)
  const bpSelectedOptionIds = [
    ...(formState?.requiredOptionGroups?.filter((d) => !!d.selectedOptionId).map((d) => d.selectedOptionId!) || []),
    ...(formState?.optionalOptionGroups || []),
  ];

  // Get all option groups that have selected IDs for static use after a report has been finalized
  const bpSelectedRpOptionGroups = {
    optionGroups: optionGroups.map((d) => ({
      ...d,
      options: d.options.filter((o) => bpSelectedOptionIds.includes(o.id)),
    })) as BlueprintReadyPlanOptionGroup[],
  };

  const readyPlanSpecLevel = bpSelectedRpOptionGroups["optionGroups"]
    .find((og) => og.name === "Spec Level")
    ?.options[0]?.name.toLocaleLowerCase();

  return {
    id: formState.id,
    bp_ready_plan_id: formState.bp_ready_plan_id,
    bp_ready_plan_name: formState.readyPlanName,
    bp_ready_plan_version: formState.bp_ready_plan_version,
    bp_ready_plan_option_ids: bpSelectedOptionIds,
    bp_ready_plan_options_json: bpSelectedRpOptionGroups,
    spec_level: readyPlanSpecLevel,
    num_bedrooms: computeProgramData?.bedrooms,
    num_baths: computeProgramData?.fullBaths,
    num_half_baths: computeProgramData?.halfBaths,
    num_stories: computeProgramData?.stories,
    num_garage_attached: computeProgramData?.garageAttached,
    num_garage_port: computeProgramData?.garagePort ?? 0,
    sellable_sqft: computeProgramData?.sellableSqft,
    sellable_basement_sqft: computeProgramData?.sellableBelowGroundSqft,
    above_ground_sqft: computeProgramData?.sellableAboveGroundSqft,
    below_ground_sqft: computeProgramData?.unfinishedBelowGroundSqft,
    width_in_inches: computeProgramData?.widthInInches,
    depth_in_inches: computeProgramData?.depthInInches,
    direct_hard_cost_in_cents: computeReadyPlanCost?.directHardCostInCents,
    indirect_hard_cost_in_cents: computeReadyPlanCost?.indirectHardCostInCents,
    soft_cost_in_cents: computeReadyPlanCost?.softCostInCents,
    // TODO: Will we need to include basement sellable or is it already included?
    //  Only getting null results for basements sqfts atm
    hard_costs_per_sqft:
      computeReadyPlanCost?.directHardCostInCents && computeProgramData?.sellableSqft
        ? computeReadyPlanCost?.directHardCostInCents / 100 / computeProgramData?.sellableSqft
        : null,
  };
}

export function getSelectedIdsFromForm(form: V2ReadyPlanFormState) {
  return [
    ...(form.optionalOptionGroups.value ?? []),
    ...form.requiredOptionGroups.rows.filter((r) => !!r.selectedOptionId.value).map((r) => r.selectedOptionId.value!),
  ];
}
