import { Maybe } from "src/utils/types";

export const numDecimalPlaces = 2;
export const centsToDollars = (cents: number) => cents / 100;
export const dollarsToCents = (dollars: number) => Math.round(dollars * 100);

export function normalizeNumberString(value: string, allowNegative?: boolean): string {
  // First determine if the number is negative. Remove the leading `$` if any, then check for leading `-`
  const isNegative = value.replace(/^\$/, "").charAt(0) === "-";

  // Replace all non-digit characters except for the `.`, and then split into an array based on the '.'
  const values = value.replace(/[^\d.]/g, "").split(".");

  // The first part of the array is our whole number - shift that out.
  const wholeNumber = values.shift();
  // Get fractional values, if any, from remaining values array.
  const fraction = values.length ? `.${values.join("")}` : "";

  const normalizedValue = `${wholeNumber}${fraction}`;

  // Add negative sign back in if applicable
  if (allowNegative && isNegative) {
    return `-${normalizedValue}`;
  }

  return normalizedValue;
}

/** Common localization logic for formatting numbers to strings. */
export function formatNumberToString(val: Maybe<number>, trim?: boolean, truncate?: boolean) {
  if (trim === false && truncate === true) {
    throw new Error("Cannot truncate without trimming");
  }

  return val == null || isNaN(val) || val === 0
    ? "0"
    : val.toLocaleString(undefined, {
        minimumFractionDigits: trim ? 0 : numDecimalPlaces,
        maximumFractionDigits: truncate ? 0 : numDecimalPlaces,
      });
}

/** Adds decimal places to a number and strips trailing zeros */
export function trimNumber(val: number): number {
  return isNaN(val) ? 0 : parseFloat(val.toFixed(numDecimalPlaces));
}

/** Calculates percentage value. Returns 0 if denominator is 0 */
export function calcPercent(numerator: number, denominator: number) {
  return !denominator ? 0 : (numerator / denominator) * 100;
}

export function calculateMarkupPercentage(price: number, cost: number, toFixed: boolean = false): number {
  const markup = cost === 0 ? 0 : ((price - cost) / cost) * 100;
  return toFixed ? parseFloat(markup.toFixed(2)) : markup;
}

// Doesn't handle billions
export function formatMagnitude(number: Maybe<number>) {
  // Check if the input is a valid number
  if (typeof number !== "number" || isNaN(number)) {
    return "-";
  }

  if (Math.abs(number) >= 1e6) {
    return (number / 1e6).toFixed(2) + " M";
  } else if (Math.abs(number) >= 1e3) {
    return (number / 1e3).toFixed(0) + " K";
  } else {
    return number.toFixed();
  }
}
